module services {
    export module tariff {
        export class productTariffService implements interfaces.tariff.IProductTariffService {
            static $inject = ["$resource", "ENV", "$http", "generalService", "$timeout"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig, private $http: ng.IHttpService, public generalService: interfaces.applicationcore.IGeneralService,
                private $timeout: ng.ITimeoutService) {
            }

            getProductTariffList(): ng.resource.IResourceClass<interfaces.tariff.IProductTariffDisplay> {
                return this.$resource<interfaces.tariff.IProductTariffDisplay>(this.ENV.DSP_URL + "TariffProduct/GetProducts", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1
                            }
                        }
                    });
            }

            getProductTariffListExcel(params: any): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'TariffProduct/GetProductsExcel?';

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                searchObject = params.connectSearch;

                url += 'connectSearch=' + btoa(JSON.stringify(searchObject));

                if (params.items)
                {
                    _.forEach(params.items, (item: string) => {
                        url += "&items=" + item;
                    });
                }
                    

                if (params.entityCode)
                    url += "&entityCode=" + params.entityCode;

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'download.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            GetSKUDropdownList(productId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "TariffProduct/GetSKUDropdownList", {
                    productId: productId
                });
            }

            GetHistory(productCountryNationalMeasureId: number): ng.resource.IResourceClass<interfaces.tariff.IProductCountryNationalMeasureHistory> {

                return this.$resource<interfaces.tariff.IProductCountryNationalMeasureHistory>(this.ENV.DSP_URL + "TariffProduct/GetHistory", {
                    productCountryNationalMeasureId: productCountryNationalMeasureId
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1
                            }
                        }
                    });
            }

            GetTariffProduct(): ng.resource.IResourceClass<interfaces.tariff.IProductTariff> {
                return this.$resource<interfaces.tariff.IProductTariff>(this.ENV.DSP_URL + "TariffProduct/GetTariffProduct", {
                    Id: '@Id'
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            params: {
                                Id: 0
                            },
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            GetTariffSettings(): ng.resource.IResourceClass<interfaces.tariff.ITariffSetting> {
                return this.$resource<interfaces.tariff.ITariffSetting>(this.ENV.DSP_URL + "TariffProduct/GetTariffSettings", {
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }


            ChangePriority(tprId, priorityId): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "TariffProduct/ChangePriority", {
                    tprId: tprId,
                    priorityId: priorityId              
                }, {
                        query: {
                            method: 'POST',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }


            ChangeTariffComments(tprId, SupplierTariffCode, OriginalTariffComments, TariffComments, AdditionalDescription): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "TariffProduct/ChangeTariffComments", {
                    tprId: tprId,
                    SupplierTariffCode: SupplierTariffCode,
                    OriginalTariffComments: OriginalTariffComments,
                    TariffComments: TariffComments,
                    AdditionalDescription: AdditionalDescription
                }, {
                        query: {
                            method: 'POST',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            SubscribeToTariffingCommentsAddedEvent(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "TariffProduct/SubscribeToTariffingCommentsAddedEvent", {
                }, {
                        query: {
                            method: 'POST',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            SubscribeToTariffingPriorityChangedEvent(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "TariffProduct/SubscribeToTariffingPriorityChangedEvent", {
                }, {
                        query: {
                            method: 'POST',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "TariffProduct/Save");
            }

            AddTariffCode(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "TariffProduct/AddTariffCode");
            }

            MultipleProductTariff(productIds, tariffCode, CountryId, codeTypeClassificationValueId, statusId, startDate): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "TariffProduct/MultipleProductTariff", {
                    productIds: productIds,
                    tariffCode: tariffCode,
                    CountryId: CountryId,
                    codeTypeClassificationValueId: codeTypeClassificationValueId,
                    statusId: statusId,
                    startDate: startDate
                });
            }

            DeleteCG(Id): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "TariffProduct/DeleteCG", {
                    Id: Id
                });
            }

            GetMultipleProductTariffingGrid(productIds): ng.resource.IResourceClass<interfaces.tariff.IProductTariffDetail> {
                return this.$resource<interfaces.tariff.IProductTariffDetail>(this.ENV.DSP_URL + "TariffProduct/GetMultipleProductTariffingGrid", {
                    productIds: productIds
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }
            
            SaveChangesToMultipleProductTariffGrid(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "TariffProduct/SaveChangesToMultipleProductTariffGrid");
            }

            GetTariffProductDetailList(): ng.resource.IResourceClass<interfaces.tariff.IProductTariffDetail> {
                return this.$resource<interfaces.tariff.IProductTariffDetail>(this.ENV.DSP_URL + "TariffProduct/GetTariffProductDetailList", {
                    Id: '@Id',
                    showAll: '@showAll',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            GetProductPermits(): ng.resource.IResourceClass<interfaces.tariff.IProductPermitDetail> {
                return this.$resource<interfaces.tariff.IProductPermitDetail>(this.ENV.DSP_URL + "TariffProduct/GetProductPermits", {
                    Id: '@Id',
                    showAll: '@showAll',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            GetPermitForProductDropDownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "TariffProduct/GetPermitsForProduct", {
                    productId: '@productId',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            //Gets a list of Product Sizes to display on the grid.
            GetProductSize(): ng.resource.IResourceClass<interfaces.tariff.IProductSizeColourDisplay> {
                return this.$resource<interfaces.tariff.IProductSizeColourDisplay>(this.ENV.DSP_URL + "TariffProduct/GetProductSize", {
                    entityId: '@entityId',
                    description: '@description',
                    sizeCode: '@sizeCode',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            //Gets a list of Product Colours to display on the grid.
            GetProductColour(): ng.resource.IResourceClass<interfaces.tariff.IProductSizeColourDisplay> {
                return this.$resource<interfaces.tariff.IProductSizeColourDisplay>(this.ENV.DSP_URL + "TariffProduct/GetProductColour", {
                    entityId: '@entityId',
                    description: '@description',
                    colourCode: '@colourCode',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            //SaveProductSizes
            SaveProductSizes(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "TariffProduct/SaveProductSizes", {
                });
            }

            //SaveProductColours
            SaveProductColours(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "TariffProduct/SaveProductColours", {
                });
            }

            //Makes lines inactive
            removeProductColor(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "TariffProduct/RemoveProductColor", {
                    prodId: '@prodId',
                });
            }

            removeProductSize(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "TariffProduct/RemoveProductSize", {
                    prodId: '@prodId',
                });
            }

            MultipleProductTariffStatusChange(productIds, statusId): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "TariffProduct/MultipleProductTariffStatusChange", {
                    productIds: productIds,
                    statusId: statusId
                });
            }
        }
    }
    angular.module("app").service("productTariffService", services.tariff.productTariffService);
}